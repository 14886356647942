import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as qs from 'query-string';
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert';
import PulseLoader from "react-spinners/PulseLoader";
import './App.css';
import { fluxovotacao } from './data/fluxovotacao';
import useSound from 'use-sound';
import somTecla from './assets/sounds/tecla.mp3';
import somTeclaConfirma from './assets/sounds/confirma.mp3';
import somTeclaFim from './assets/sounds/fim.mp3';

const url_basic = process.env.REACT_APP_BASIC_URL;
const api = process.env.REACT_APP_API;

function App() {
  const [numcandidato, setNumCandidato] = useState('');
  const [fecthmsg, setFecthMsg] = useState('');
  const [digito, setDigito] = useState([]);
  const [items, setItems] = useState([]);
  const [dadosdavotacao, setDadosdaVotacao] = useState([]);
  const [rscampanhaativa, setRscampanhaativa] = useState([]);
  const [rseleitor, setRseleitor] = useState([]);
  const [rscandidadto, setRsCandidadto] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [issaving, setIsSaving] = useState(false);
  const [javotou, setJaVotou] = useState(false);
  const [votacaofinalizada, setVotacaoFinalizada] = useState(false);
  const [campanhaativa, setCampanhaAtiva] = useState(true);
  const [votonulo, setVotoNulo] = useState(false);
  const [votobranco, setVotoBranco] = useState(false);
  const [indexfluxo, setIndexFluxo] = useState(0);
  const [playTecla] = useSound(somTecla, { volume: 0.25 });
  const [playTeclaConfirma] = useSound(somTeclaConfirma, { volume: 0.25 });
  const [playTeclaFim] = useSound(somTeclaFim, { volume: 0.5 });

  let vtBranco = false;

  function insereNum(val) {
    digito.push(val);
    setNumCandidato(numcandidato + val);

    if (digito.length <= fluxovotacao[indexfluxo].digitos) {
      playTecla();
      buscaCandidato(numcandidato + val, '214407771051', fluxovotacao[indexfluxo].cargo);
    }
  }

  function dois_digitos(numero) {
    return numero <= 9 ? '0' + numero.toString() : numero.toString();
  }

  var dias_semana = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"];

  function exibir_relogio() {
    var hoje = new Date();
    var data_atual = dois_digitos(hoje.getDate()) + '/' + dois_digitos(hoje.getMonth() + 1) + '/' + dois_digitos(hoje.getFullYear());
    var hora_atual = dois_digitos(hoje.getHours()) + ':' + dois_digitos(hoje.getMinutes());
    return dias_semana[hoje.getDay()] + ' ' + data_atual + ' ' + hora_atual;
  }

  var relogio = exibir_relogio();

  function trataErro(erro) {
    throw new Error(erro.message);
  }

  async function buscaCandidato(numero, estadoid, cargoid) {
    const session_url = `${api}/candidatocargo?numero=${numero}&estadoid=${estadoid}&cargoid=${cargoid}`;
    var config = {
      method: 'get',
      url: session_url
    };

    if (digito.length === fluxovotacao[indexfluxo].digitos) {
      setIsLoading(true);
      setRsCandidadto([]);
      setFecthMsg('');
      const retorno = await axios(config)
        .then((response) => {
          setIsLoading(false);
          setRsCandidadto(response.data);
          //console.log(response.data);
        })
        .catch(erro => {
          setIsLoading(false);
          setFecthMsg('NÚMERO ERRADO');
          // trataErro(erro);
        });
    }
  }

  const boxDigito = (digitos) => {
    const datas = [];
    for (let i = 0; i < digitos; i++) {
      datas[i] = {
        number: i + 1 - 1,
        description: `desc${i}`
      }
    }
    setItems(datas)
  }

  function Box({ item }) {
    return <div className={digito.length === digito[item.number] ? "cxNumeroPiscando" : "cxNumero1"}>{digito[item.number]}</div>
  }

  const Alerta = () => {
    return (
      <div className='content'>
        <Alert variant="danger">
          <Alert.Heading>Não existe campanha de votação ativa no momento!</Alert.Heading>
          <br />
          <p>
            Quando a campanha estiver ativa, você será liberado para realizar a votação e exercer seu direito no ambiente de votação Eu Voto, peço que aguarde a liberaçao da campanha e volte para realizar sua votação.
          </p>
          <hr />
          <p className="mb-0">
            Para informações entre em contato com o time de suporte da Eu Voto.
          </p>
        </Alert>

      </div>
    )
  }

  const AlertaJaVotou = () => {
    return (
      <div className='content'>
        <Alert variant="warning">
          <Alert.Heading>Votação já realizada!</Alert.Heading>
          <br />
          <p>
            Identificamos que você já realizou a votação nesta campanha. Aguarde a liberaçao para votar na próxima campanha.
          </p>
          <hr />
          <p className="mb-0">
            Para informações entre em contato com o time de suporte da Eu Voto.
          </p>
        </Alert>

      </div>
    )
  }

  useEffect(() => {
    const parsed = qs.parse(window.location.search);
    if (parsed.token) {
      fetch(`${api}/campanhaativa`)
        .then(response => response.json())
        .then(result => {
          //console.log(result);
          if (result.record_count > 0) {
            var decoded = jwt_decode(parsed.token);
            setRseleitor(decoded);
            //console.log(decoded);
            setCampanhaAtiva(true);
            setRscampanhaativa(result.dados[0]);

            // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "ci_session=0b689a82fa31d3f1239b4d87ede7cde7aabb10cf");

            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };

            fetch(`${api}/votounacampanhaativa?userid=${decoded.userid}`, requestOptions)
              .then(response => response.json())
              .then(result => {
                if (result.record_count > 0) {
                  setJaVotou(true);
                } else {
                  setJaVotou(false);
                }
              })
              .catch(error => console.log('error', error));

          } else {
            setRscampanhaativa([]);
            setCampanhaAtiva(false);
          }
        })
        .catch(error => {
          console.log('error', error);
          setCampanhaAtiva(false);
        });
    } else {
      setCampanhaAtiva(false);
    }

  }, []);

  useEffect(() => {
    boxDigito(fluxovotacao[indexfluxo].digitos);
  }, [indexfluxo]);


  return (
    <div className="property-1-default">
      {campanhaativa ? javotou ? (<AlertaJaVotou />) : (
        <div className="overlap-group-1">
          <h1 className="title valign-text-middle poppins-extra-bold-white-24px">
            EU VOTO PARA
          </h1>
          <div className="flex-row">
            {votacaofinalizada ?
              (
                <div className="onboard-final-votacao">
                  <h1 className="final-votacao">
                    {issaving ? "SALVANDO VOTAÇÃO..." : "VOTAÇÃO FINALIZADA"}
                  </h1>
                </div>
              )
              :
              (
                <>
                  <div className="onboard">
                    {isloading ? <PulseLoader color="#696969" size={5} /> : rscandidadto.length !== 0 ? <img src={`${url_basic}/source/nominee/${rscandidadto.dados[0].imagelocation}`} className="onboard-3" ></img> : ''}
                  </div>
                  <div className="overlap-group-container">
                    <div className="overlap-group3">
                      <div className="cargo valign-text-middle poppins-extra-bold-white-13px">
                        CARGO
                      </div>
                      <div className="governador valign-text-middle poppins-normal-white-13px">
                        {typeof fluxovotacao[indexfluxo].nmcargo !== undefined ? fluxovotacao[indexfluxo].nmcargo : null}
                      </div>
                    </div>
                    <div className="overlap-group2">
                      <div className="nmero valign-text-middle poppins-extra-bold-white-13px">
                        NÚMERO
                      </div>
                      <div className="number-3 valign-text-middle poppins-normal-white-20px">
                        <div className="cxNumeros">
                          {items.map((item, index) => <Box item={item} />)}
                        </div>
                      </div>
                    </div>
                    <div className="overlap-group5">
                      <div className="place valign-text-middle poppins-extra-bold-white-13px">
                        {rscandidadto.length !== 0 ? 'NOME' : null}
                      </div>
                      <div className="helder-zahluth-barbalho poppins-normal-white-13px">
                        {isloading ? <PulseLoader color="#fff" size={5} /> : rscandidadto.length !== 0 ? rscandidadto.dados[0].name : fecthmsg}
                      </div>
                    </div>
                    <div className="overlap-group4">
                      <div className="partido valign-text-middle poppins-extra-bold-white-13px">
                        {rscandidadto.length !== 0 ? 'PARTIDO' : null}
                      </div>
                      <div className="mdb valign-text-middle poppins-normal-white-13px">
                        {rscandidadto.length !== 0 ? rscandidadto.dados[0].partiesid : null}
                      </div>
                    </div>
                  </div>
                </>
              )
            }
          </div>

          <div className="flex-col">
            <p className="aperte-a-tecla-conf valign-text-middle play-bold-white-13px">
              Aperte a tecla: CONFIRMA para PROSSEGUIR <br />
              CORRIGE para REINICIAR este voto
            </p>
            <div className="flex-row-1">
              <div className="overlap-group-container-1">
                <div className="overlap-group6 naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('1');
                  }
                }}>
                  <div className="number valign-text-middle poppins-semi-bold-white-32px">
                    1
                  </div>
                </div>
                <div className="overlap-group10 naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('4');
                  }
                }}>
                  <div className="number valign-text-middle poppins-semi-bold-white-32px">
                    4
                  </div>
                </div>
                <div className="overlap-group9 naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('7');
                  }
                }}>
                  <div className="number valign-text-middle poppins-semi-bold-white-32px">
                    7
                  </div>
                </div>
              </div>
              <div className="overlap-group-container-2">
                <div className="overlap-group12 naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('2');
                  }
                }}>
                  <div className="number-1 valign-text-middle poppins-semi-bold-white-32px">
                    2
                  </div>
                </div>
                <div className="overlap-group15 naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('5');
                  }
                }}>
                  <div className="number-1 valign-text-middle poppins-semi-bold-white-32px">
                    5
                  </div>
                </div>
                <div className="overlap-group naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('8');
                  }
                }}>
                  <div className="number-1 valign-text-middle poppins-semi-bold-white-32px">
                    8
                  </div>
                </div>
                <div className="overlap-group naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('0');
                  }
                }}>
                  <div className="number-1 valign-text-middle poppins-semi-bold-white-32px">
                    0
                  </div>
                </div>
              </div>
              <div className="overlap-group-container-3">
                <div className="overlap-group8 naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('3');
                  }
                }}>
                  <div className="number-2 valign-text-middle poppins-semi-bold-white-32px">
                    3
                  </div>
                </div>
                <div className="overlap-group16 naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('6');
                  }
                }}>
                  <div className="number-2 valign-text-middle poppins-semi-bold-white-32px">
                    6
                  </div>
                </div>
                <div className="overlap-group17 naoSelecionavel" onClick={() => {
                  if (!votacaofinalizada) {
                    insereNum('9');
                  }
                }}>
                  <div className="number-2 valign-text-middle poppins-semi-bold-white-32px">
                    9
                  </div>
                </div>
              </div>
              <div className="overlap-group-container-4">
                <div className="overlap-group11 border-1px-black naoSelecionavel"
                  onClick={() => {
                    if (!votacaofinalizada) {
                      //setNumCandidato('00');
                      playTecla();
                      if (votobranco === true) {
                        setDigito([]);
                        setNumCandidato('');
                        setFecthMsg('');
                        setVotoBranco(false);
                        playTeclaConfirma();
                        setRsCandidadto([]);
                        if (indexfluxo === 4) {
                          setIndexFluxo(0);
                          playTeclaFim();
                          setVotacaoFinalizada(true);
                        } else {
                          setIndexFluxo(indexfluxo + 1);
                        }
                      } else {
                        setFecthMsg('VOTAR EM BRANCO?');
                        setNumCandidato('');
                        setDigito([]);
                        vtBranco = true;
                        setVotoBranco(true);
                        playTeclaConfirma();
                      }

                    }
                  }}
                >
                  <div className="place-1 valign-text-middle play-bold-black-13px">
                    BRANCO
                  </div>
                </div>
                <div className="overlap-group14 border-1px-black naoSelecionavel"
                  onClick={() => {
                    if (!votacaofinalizada) {
                      setDigito([]);
                      setRsCandidadto([]);
                      setVotoNulo(false);
                      setVotoBranco(false);
                      setNumCandidato('');
                      setFecthMsg('');
                      playTeclaConfirma();
                    }
                  }}
                >
                  <div className="corrige valign-text-middle play-bold-black-13px">
                    CORRIGE
                  </div>
                </div>
                {rscandidadto.length !== 0 ? (
                  <>
                    <div className="overlap-group18 border-1px-black naoSelecionavel"
                      onClick={() => {
                        if (!votacaofinalizada) {
                          setDigito([]);
                          setNumCandidato('');
                          playTeclaConfirma();
                          setRsCandidadto([]);
                          if (indexfluxo === 4) {
                            dadosdavotacao.push({
                              userid: rseleitor.userid,
                              numero: rscandidadto.dados[0].userid ? rscandidadto.dados[0].userid : votobranco ? "B" : votonulo ? "N" : "",
                              estado: rscandidadto.dados[0].organizationid ? rscandidadto.dados[0].organizationid : votobranco ? "B" : votonulo ? "N" : "",
                              cargo: fluxovotacao[indexfluxo].cargo,
                              campanha: rscampanhaativa.id
                            });

                            setIsSaving(true);
                            var raw = JSON.stringify({
                              "dados": dadosdavotacao
                            });

                            var requestOptions = {
                              method: 'POST',
                              body: raw,
                              redirect: 'follow'
                            };

                            fetch(`${api}/votar`, requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                //console.log(result);
                                setIsSaving(false);
                                if (result.record_count == 1) {
                                  setIndexFluxo(0);
                                  playTeclaFim();
                                  setVotacaoFinalizada(true);
                                  setDadosdaVotacao([]);
                                } else {
                                  setFecthMsg('NÃO FOI POSSIVEL SALVAR SEU VOTO, TENTE NOVAMENTE!');
                                }
                              })
                              .catch(error => {
                                console.log('error', error);
                                setIsSaving(false);
                              });


                            console.log(dadosdavotacao);
                          } else {
                            setIndexFluxo(indexfluxo + 1);
                            dadosdavotacao.push({
                              userid: rseleitor.userid,
                              numero: rscandidadto.dados[0].userid ? rscandidadto.dados[0].userid : votobranco ? "B" : votonulo ? "N" : "",
                              estado: rscandidadto.dados[0].organizationid ? rscandidadto.dados[0].organizationid : votobranco ? "B" : votonulo ? "N" : "",
                              cargo: fluxovotacao[indexfluxo].cargo,
                              campanha: rscampanhaativa.id
                            });
                          }
                        }
                        console.log(dadosdavotacao);
                      }}
                    >
                      <div className="confirma valign-text-middle play-bold-black-13px">
                        CONFIRMA
                      </div>
                    </div>

                  </>
                ) : (
                  <>
                    <div className="overlap-group18 border-1px-black"
                      onClick={() => {
                        if (!votacaofinalizada) {
                          if (votonulo === true || votobranco === true) {
                            setDigito([]);
                            setNumCandidato('');
                            setFecthMsg('');
                            //setVotoNulo(false);
                            //setVotoBranco(false);
                            playTeclaConfirma();
                            setRsCandidadto([]);
                            if (indexfluxo === 4) {
                              dadosdavotacao.push({
                                userid: rseleitor.userid,
                                numero: votobranco ? "B" : votonulo ? "N" : "",
                                estado:"214407771051",
                                cargo: fluxovotacao[indexfluxo].cargo,
                                campanha: rscampanhaativa.id
                              });
                              
                              setVotoNulo(false);
                              setVotoBranco(false);

                              setIsSaving(true);
                              var raw = JSON.stringify({
                                "dados": dadosdavotacao
                              });
  
                              var requestOptions = {
                                method: 'POST',
                                body: raw,
                                redirect: 'follow'
                              };
  
                              fetch(`${api}/votar`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                  //console.log(result);
                                  setIsSaving(false);
                                  if (result.record_count == 1) {
                                    setIndexFluxo(0);
                                    playTeclaFim();
                                    setVotacaoFinalizada(true);
                                    setDadosdaVotacao([]);
                                  } else {
                                    setFecthMsg('NÃO FOI POSSIVEL SALVAR SEU VOTO, TENTE NOVAMENTE!');
                                  }
                                })
                                .catch(error => {
                                  console.log('error', error);
                                  setIsSaving(false);
                                });
  
  
                              console.log(dadosdavotacao);
                            } else {
                              setIndexFluxo(indexfluxo + 1);
                              console.log('Voto branco', votobranco);
                              console.log('Voto Nulo', votonulo);
                              dadosdavotacao.push({
                                userid: rseleitor.userid,
                                numero: votobranco ? "B" : votonulo ? "N" : "",
                                estado: "214407771051",
                                cargo: fluxovotacao[indexfluxo].cargo,
                                campanha: rscampanhaativa.id
                              });
                              setVotoNulo(false);
                              setVotoBranco(false);
                              console.log('Novo estado do Voto branco', votobranco);
                            }
                          } else {
                            setFecthMsg('DESEJA ANULAR SEU VOTO?');
                            setVotoNulo(true);
                            setVotoBranco(false);
                            playTeclaConfirma();
                          }
                        }
                        console.log(dadosdavotacao);
                      }}
                    >
                      <div className="confirma valign-text-middle play-bold-black-13px">
                        CONFIRMA
                      </div>
                    </div>
                  </>
                )
                }
              </div>
            </div>
            <div className="text-1 valign-text-middle play-normal-black-12px">
              {relogio}
            </div>
          </div>
        </div>
      ) :
        (<Alerta />)
      }
    </div>

  );
}

export default App;