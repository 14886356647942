const fluxovotacao = [
    {
        cargo: 347430924766,
        nmcargo: "DEPUTADO FEDERAL",
        digitos: 4
    },
    {
        cargo: 341298181341,
        nmcargo: "DEPUTADO ESTADUAL",
        digitos: 5
    },
    {
        cargo: 251972032522,
        nmcargo: "SENADOR",
        digitos: 3
    },
    {
        cargo: 266273118254,
        nmcargo: "GOVERNADOR",
        digitos: 2
    },
    {
        cargo: 238770789328,
        nmcargo: "PRESIDENTE",
        digitos: 2
    }
]

export { fluxovotacao };